import { Container } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import CrossIcon from "../../assets/svg/GFW_cross.svg"

interface QuoteProps {
  body: string
  tags?: string[]
}
const PortfolioQuote: FunctionComponent<QuoteProps> = ({ body, tags }) => {
  return (
    <Container maxWidth={"md"}>
      <Wrapper>
        <BlueBox />
        <TextContainer>
          <Cross />
          <Quote>{body}</Quote>

          {tags && (
            <TagContainer>
              {tags.map((item, i) => {
                return <Tag>{item}</Tag>
              })}
            </TagContainer>
          )}
        </TextContainer>
      </Wrapper>
    </Container>
  )
}

export default PortfolioQuote

const Wrapper = styled.div`
  height: 600px;
`

const Quote = styled.h5`
  color: black;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 16px;
  }
`

const BlueBox = styled.div`
  border: 30px solid ${props => props.theme.colors.quoteBox};
  margin-right: 50%;
  height: 550px;
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 20%;
  }
`

const TextContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
  top: -470px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    padding-left: 60px;
  }
`

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-right: 50px;
  margin-left: -5px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-right: 0%;
  }
`
const Tag = styled.b`
  text-align: center;
  text-transform: uppercase;
  background-color: black;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 5px;
  height: 20px;
  width: auto;
  padding: 0px 8px 0px;
  border-radius: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10px;
    line-height: 18px;
  }
`
const Cross = styled(CrossIcon)`
  max-width: 100px;
  max-height: 100px;
  padding: 0px;
  margin: 20px 0;

  z-index: 1;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 40px;
    margin: 0;
  }
`
