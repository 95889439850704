import React, { FunctionComponent } from "react"
import styled from "styled-components"

// import Cursor from "./Cursor"

import SwiperCore, { Navigation, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container } from "@material-ui/core"

// install Swiper components
SwiperCore.use([Navigation, Scrollbar])

interface IImage {
  sourceUrl: string
}

interface CarouselDragProps {
  id: string
  captions: string[]
  images: Array<IImage>
}
const FeaturedImageScroll: FunctionComponent<CarouselDragProps> = ({ id, images, captions }) => {
  return (
    <Container>
      <TextContainer>
        <MyWork>MY WORK</MyWork>
        <h4>PORTFOLIOS</h4>
      </TextContainer>
      <Container2>
        <Swiper className="swiper-container" slidesPerView={1} navigation scrollbar={{ hide: false }}>
          {images.map((item, i) => {
            return (
              item && (
                <SwiperSlide key={i} className="carousel-drag-slide">
                  <img src={item.sourceUrl} style={{ height: "100%", width: "100%" }} />
                  <Caption>
                    {captions[i]&&
                    <h6>{captions[i]}</h6>
                    }
                  </Caption>
                </SwiperSlide>
              )
            )
          })}
        </Swiper>
      </Container2>
    </Container>
  )
}

export default FeaturedImageScroll

const Container2 = styled.section`
  width: 100%;
  .swiper-container {
    padding: 0 0 100px; //so the scroll bar is visible bellow the slides
    transition: transform 0.8s ease;
    width: 100%;
    height: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    margin: auto; //for horizontal centering
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: calc(100vw - 140px);
    background: ${props => props.theme.colors.turquoise};
    left: 100px;
    bottom: 68px;
    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${props => props.theme.colors.turquoise};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 60px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny44NCAxMS4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZGIwZTc7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgY2xhc3M9ImNscy0xIiBkPSJNNDUuMzgsNiw0MS4xNiwxLjg0bDEtMSw1LjcsNS42Mi01LjcsNS42Mi0xLTFMNDUuMzgsN0gwVjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjg4KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    /* background-color: ${props => props.theme.colors.turquoise}; */
    background-size: 100% auto;
    background-position: center;
    width: 45px;
    height: 21px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    /* background: url(../assets/svg/long-arrow-right.svg); */
    background-repeat: no-repeat;
    background-size: 100% auto;

    background-position: center;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  align-items: center;
  margin: 0 auto;
`
const MyWork = styled.h6`
  font-size: 14px;
  letter-spacing: 2px;
  color: black;
  font-weight: 500;
`

const Caption = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
`
