import React from "react"
import { graphql } from "gatsby"

import { Layout } from "../components/layout"
import { PortfolioHeader } from "../components/PortfoliosTemplate/PortfolioHeader"

import Breadcrumb from "../components/Breadcrumb"

import { Container } from "@material-ui/core"
import CrossHeadingTwo from "../components/CrossHeadingTwo"
import ImageWithSplitTextOverlap from "../components/LazyBlock/ImageWithSplitTextOverlap"
import FeaturedImageScroll from "../components/PortfoliosTemplate/FeaturedImageScroll.tsx"

import { SplitImageVideoWithText } from "../components/LazyBlock/SplitImageVideoWithText"
import PortfolioQuote from "../components/PortfoliosTemplate/PortfolioQuote"

interface Props {
  data: any
  pageContext: any
}
const PortfolioTemplate = ({ data, pageContext }: Props) => {
  const partners = data.allWpPartner.edges.map(v => v.node)
  const studentInfo = data.wpPortfolio
  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Search Portfolio",
      url: "/search-portfolios",
    },
    {
      title: studentInfo.student_profile.studentName,
      url: "/portfolios/" + studentInfo.slug,
    },
  ]

  const featuredImages = [
    studentInfo.student_portfolio.portfolioGalleryImage1,
    studentInfo.student_portfolio.portfolioGalleryImage2,
    studentInfo.student_portfolio.portfolioGalleryImage3,
    studentInfo.student_portfolio.portfolioGalleryImage4,
    studentInfo.student_portfolio.portfolioGalleryImage5,
    studentInfo.student_portfolio.portfolioGalleryImage6,
    studentInfo.student_portfolio.portfolioGalleryImage7,
    studentInfo.student_portfolio.portfolioGalleryImage8,
    studentInfo.student_portfolio.portfolioGalleryImage9,
    studentInfo.student_portfolio.portfolioGalleryImage10,
    studentInfo.student_portfolio.portfolioGalleryImage11,
    studentInfo.student_portfolio.portfolioGalleryImage12,
    studentInfo.student_portfolio.portfolioGalleryImage13,
    studentInfo.student_portfolio.portfolioGalleryImage14,
    studentInfo.student_portfolio.portfolioGalleryImage15,
    studentInfo.student_portfolio.portfolioGalleryImage16,
    studentInfo.student_portfolio.portfolioGalleryImage17,
    studentInfo.student_portfolio.portfolioGalleryImage18,
    studentInfo.student_portfolio.portfolioGalleryImage19,
    studentInfo.student_portfolio.portfolioGalleryImage20,
  ]

  const featureImageCaptions = [
    studentInfo.student_portfolio.portfolioGalleryCaption1,
    studentInfo.student_portfolio.portfolioGalleryCaption2,
    studentInfo.student_portfolio.portfolioGalleryCaption3,
    studentInfo.student_portfolio.portfolioGalleryCaption4,
    studentInfo.student_portfolio.portfolioGalleryCaption5,
    studentInfo.student_portfolio.portfolioGalleryCaption6,
    studentInfo.student_portfolio.portfolioGalleryCaption7,
    studentInfo.student_portfolio.portfolioGalleryCaption8,
    studentInfo.student_portfolio.portfolioGalleryCaption9,
    studentInfo.student_portfolio.portfolioGalleryCaption10,
    studentInfo.student_portfolio.portfolioGalleryCaption11,
    studentInfo.student_portfolio.portfolioGalleryCaption12,
    studentInfo.student_portfolio.portfolioGalleryCaption13,
    studentInfo.student_portfolio.portfolioGalleryCaption14,
    studentInfo.student_portfolio.portfolioGalleryCaption15,
    studentInfo.student_portfolio.portfolioGalleryCaption16,
    studentInfo.student_portfolio.portfolioGalleryCaption17,
    studentInfo.student_portfolio.portfolioGalleryCaption18,
    studentInfo.student_portfolio.portfolioGalleryCaption19,
    studentInfo.student_portfolio.portfolioGalleryCaption20,
  ]

  let studentHero
  try {
    studentHero = studentInfo.student_profile.headerImage.localFile.childImageSharp.gatsbyImageData
  } catch (e) {
    // console.error("Issue with studnet Hero image.")
    // console.error(e)
  }

  // console.log(studentInfo)

  return (
    <Layout partners={partners} title={"Graduate Fashion Week - "}>
      <div style={{ backgroundColor: "#fafafa", width: "100%" }}>
        <Container maxWidth={"lg"}>
          {/* <BreadCrumbContainer> */}
          <Breadcrumb data={breadcrumbData} />
          {/* </BreadCrumbContainer> */}
        </Container>
      </div>

      <PortfolioHeader
        title={studentInfo.student_profile.studentName}
        img={studentHero}
        tags={studentInfo.student_profile.specialism}
        email={studentInfo.student_profile.contactEmailAddress}
        instagram={studentInfo.student_profile.instagramLink}
        linked={studentInfo.student_profile.linkedinLink}
        youtube={studentInfo.student_profile.youtubeLink}
        vimeo={studentInfo.student_profile.vimeoLink}
        website={studentInfo.student_profile.websiteLink}
        university={studentInfo.student_profile.universitySchool}
      />

      <CrossHeadingTwo
        data={{
          attrs: {
            heading: studentInfo.student_portfolio.aboutMeHeading,
            body: studentInfo.student_portfolio.aboutMeParagraph1,
            "body-2": studentInfo.student_portfolio.aboutMeParagraph2,
            subtitle: "About Me",
          },
        }}
      />

      <ImageWithSplitTextOverlap
      
        data={{
          attrs: {
            subtitle:"INSPIRATION",

            body:
            "<h4>"+
            studentInfo.student_portfolio.inspiration +
            "</h4>"+
              "<p>" +
              studentInfo.student_portfolio.inspirationParagraph1 +
              "</p><p>" +
              studentInfo.student_portfolio.inspirationParagraph2 +
              "</p>",
            image: studentInfo.student_portfolio.inspirationImage,
            jsonFile: false,
          },
        }}
      />

      <FeaturedImageScroll images={featuredImages} captions={featureImageCaptions} />
      {/* const subtitle = data.attrs.subtitle
  const body = data.attrs.body
  const videoLink = data.attrs["video-link"] */}
  
      <SplitImageVideoWithText
        data={{
          attrs: {
            subtitle: "DETAIL",
            "video-link": studentInfo.student_portfolio.detailsVideo,
            videoProvider: studentInfo.student_portfolio.videoProvider,
           placeholderImage: studentInfo.student_portfolio.detailsPlaceholderImage,
            body:
              "<h4>" +
              studentInfo.student_portfolio.detailHeading +
              "<h4>" +
              "<p>" +
              studentInfo.student_portfolio.detailParagraph +
              "</p>",
          },
        }}
      />

      <PortfolioQuote tags={studentInfo.student_profile.specialism} body={studentInfo.student_portfolio.quoteBox} />
    </Layout>
  )
}

export default PortfolioTemplate

export const pageQuery = graphql`
  query portfolioById($id: String!) {
    wpPortfolio(id: { eq: $id }) {
      id
      title
      slug
      student_profile {
        instagramLink
        fieldGroupName
        contactEmailAddress
        linkedinLink
        specialism
        studentName

        universitySchool {
          ... on WpUniversity {
            id
            title
            UniversityInfo {
              universityLocationCityForSearch
            }
          }
        }
        vimeoLink
        websiteLink
        youtubeLink

        headerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      student_portfolio {
        aboutMeHeading
        aboutMeParagraph1
        aboutMeParagraph2
        detailHeading
        detailParagraph
        detailsVideo
        fieldGroupName
        inspirationImage {
          sourceUrl
        }
        inspiration
        inspirationParagraph1
        inspirationParagraph2
        detailsVideo
        detailsPlaceholderImage{
          sourceUrl
        }
        videoProvider
        quoteBox
        portfolioGalleryCaption1
        portfolioGalleryCaption2
        portfolioGalleryCaption3
        portfolioGalleryCaption4
        portfolioGalleryCaption5
        portfolioGalleryCaption6
        portfolioGalleryCaption7
        portfolioGalleryCaption8
        portfolioGalleryCaption9
        portfolioGalleryCaption10
        portfolioGalleryCaption11
        portfolioGalleryCaption12
        portfolioGalleryCaption13
        portfolioGalleryCaption14
        portfolioGalleryCaption15
        portfolioGalleryCaption16
        portfolioGalleryCaption17
        portfolioGalleryCaption18
        portfolioGalleryCaption19
        portfolioGalleryCaption20
        portfolioGalleryImage1 {
          sourceUrl
        }
        portfolioGalleryImage2 {
          sourceUrl
        }
        portfolioGalleryImage3 {
          sourceUrl
        }
        portfolioGalleryImage4 {
          sourceUrl
        }
        portfolioGalleryImage6 {
          sourceUrl
        }
        portfolioGalleryImage7 {
          sourceUrl
        }
        portfolioGalleryImage8 {
          sourceUrl
        }
        portfolioGalleryImage9 {
          sourceUrl
        }
        portfolioGalleryImage10 {
          sourceUrl
        }
        portfolioGalleryImage11 {
          sourceUrl
        }
        portfolioGalleryImage12 {
          sourceUrl
        }
        portfolioGalleryImage13 {
          sourceUrl
        }
        portfolioGalleryImage14 {
          sourceUrl
        }
        portfolioGalleryImage15 {
          sourceUrl
        }
        portfolioGalleryImage16 {
          sourceUrl
        }
        portfolioGalleryImage17 {
          sourceUrl
        }
        portfolioGalleryImage18 {
          sourceUrl
        }
        portfolioGalleryImage19 {
          sourceUrl
        }
        portfolioGalleryImage20 {
          sourceUrl
        }
      }
    }
    allWpPartner {
      edges {
        node {
          id
          title
          slug
          PartnerInfo {
            tags
            partnershipIntro
            partnershipLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
