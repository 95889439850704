import React, { FunctionComponent } from "react"
import styled from "styled-components"

import { GatsbyImage } from "gatsby-plugin-image"

import BackArrow from "../../assets/svg/long-arrow-left.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container } from "@material-ui/core"

import IconButton from "@material-ui/core/IconButton"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import InstagramIcon from "@material-ui/icons/Instagram"
import YoutubeIcon from "@material-ui/icons/YouTube"
import VimeoIcon from "../../assets/svg/Vimeo.svg"
import WebIcon from "@material-ui/icons/Language"
import { makeStyles } from "@material-ui/core"

interface IPortfolioHeader {
  title: string
  email?: string
  university?: string[]
  tags?: string[]
  img?: any
  breadcrumb?: IBreadcrumb
  instagram?: string
  linked?: string
  youtube?: string
  vimeo?: string
  website?: string
}
interface Item {
  title: string
  url: string
}
type IBreadcrumb = Array<Item>

export const PortfolioHeader: FunctionComponent<IPortfolioHeader> = ({
  title,
  email,
  university,
  tags,
  img,

  instagram,
  linked,
  youtube,
  vimeo,
  website,
}) => {
  const useStyles = makeStyles(theme => ({
    customHoverFocus: {
      width: 35,
      height: 35,
      "&:hover, &.Mui-focusVisible": { backgroundColor: "#2e2e2e" },
      backgroundColor: "black",
      color: "",
      marginRight: 8,
    },
    icon: {
      width: 20,
      height: 20,
    },
  }))

  const classes = useStyles()
  return (
    <FullWidthContainer>
      <ImgContainer>
        <GatsbyImage
          image={img}
          //placeholderStyle={{ opacity: 0 }}
          //loading={"eager"}
          imgStyle={{
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />
      </ImgContainer>

      <Container maxWidth={"lg"}>
        <Box>
          <BackLink to={"/search-portfolios/"}>
            <BackArrow className={"image"} />
          </BackLink>

          <h1>{title}</h1>

          <Row1>
            <RowTitle>Contact: </RowTitle>
            <RowInfo1>{email}</RowInfo1>
          </Row1>
          {university.title && (
            <Row>
              <RowTitle>University/School: </RowTitle>
              <RowInfo>{university.title}</RowInfo>
            </Row>
          )}
          <Row>
            <RowTitle>Location: </RowTitle>
            <RowInfo>{university.UniversityInfo.universityLocationCityForSearch}</RowInfo>
          </Row>

          <Row>
            <RowTitle>Specialism: </RowTitle>
            {tags && (
              <TagContainer>
                {tags.map((item, i) => {
                  return <Tag>{item}</Tag>
                })}
              </TagContainer>
            )}
          </Row>

          <SocialIcons>
            {instagram && (
              <StyledIconButton
                aria-label="Instagram"
                href={instagram}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <InstagramIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {linked && (
              <StyledIconButton
                aria-label="LinkedIn"
                href={linked}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <LinkedInIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {youtube && (
              <StyledIconButton
                aria-label="Youtube"
                href={youtube}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <YoutubeIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {website && (
              <StyledIconButton
                aria-label="Website"
                href={website}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <WebIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {vimeo && (
              <StyledIconVimeoButton
                aria-label="Vimeo"
                href={vimeo}
                target="_blank"
                //className={classes.customHoverFocus}>
              >
                <VimeoIconDiv />
              </StyledIconVimeoButton>
            )}
          </SocialIcons>
        </Box>
      </Container>
    </FullWidthContainer>
  )
}

const ImgContainer = styled.div`
  width: 100%;
  max-height: 550px;
  overflow: visible;

  &img {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      object-fit: fill;
    }
  }
`

const FullWidthContainer = styled.div`
  width: 100%;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -350px;
  margin-bottom: 70px;
  max-width: 660px;
  max-height: 560px;
  padding: 13px 63px 63px;
  background-color: black;

  color: white;
  > h1 {
    color: white;
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: -350px;
    margin-bottom: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: -250px;
    margin-bottom: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: -50px;
    margin-bottom: 0px;
    padding: 10px 20px 40px;
    max-height: 600px;
  }
`
const Row1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const RowTitle = styled.h6`
  /* font-weight: bold; */
  text-transform: none;
  font-weight: 500;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 18px;
  }
`
const RowInfo = styled.h6`
  text-transform: none;
  font-weight: 200;
  padding-left: 10px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-weight: 300;
    font-size: 18px;
  }
`
const RowInfo1 = styled.h6`
  text-transform: none;
  font-weight: 200;
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-weight: 300;
    font-size: 18px;
    word-wrap: break-word !important;
  }
`

const BackLink = styled(Link)`
  height: 40px;
  width: 100px;
  margin-bottom: 30px;
  object-fit: fill;
  .image {
    height: 50px;
    width: 120px;
    object-fit: fill;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 90px;
    }
  }
`
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
const Tag = styled.b`
  text-align: center;
  text-transform: uppercase;
  border: 1.5px solid white;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 5px;
  height: 20px;
  width: auto;
  padding: 0px 8px 0px;
  border-radius: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10px;
    line-height: 16px;
  }
`
const SocialIcons = styled.div`
  flex-direction: row;
`
const StyledIconButton = styled(IconButton)`
  background-color: white !important;
  color: black;
  margin: 6px;
  overflow: visible;
  .root {
    background-color: white !important;
  }
`
const StyledIconVimeoButton = styled(IconButton)`
  background-color: white !important;
  color: black;
  margin: 4px;
  width: 35px;
  height: 35px;
  padding: 8px;

  .root {
    background-color: white !important;
  }
`
const VimeoIconDiv = styled(VimeoIcon)`
  color: black;
  background-color: black;
  border-radius: 50%;
  height: 20px;
  width: 20px !important;
  padding: 2px;
`
